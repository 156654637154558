import React from 'react'
import Layout from '../components/layout-en'
import Head from '../components/head'
import '../styles/community.css'
import {Row, Col} from 'react-bootstrap'
import {navigate} from 'gatsby'

import GooglePlay from '../images/google-store.png'
import Love from "../images/icon-com-love.png";
import Comment from "../images/icon-com-msg.png";
import Share from "../images/icon-com-share.png";

class Community extends React.Component {

    constructor(props){
      super(props);
      this.state = {
          payload: [],
          catalog: [],
          idx: 1,
      }
    }

    getPlantName(id){
        var plant_name = ""
        var catalog = this.state.catalog
        catalog = catalog.filter(function(plant){return plant["plant_id"] == id})
        if(catalog.length != 0){
            plant_name = catalog[0]["plant_name"]
        }
        return plant_name
    }

    getPartName(parts){
        var part_name = []
        for(var part in parts){
            if(part == "0") {
                part_name.push('Daun')
            } else if(part == "1") {
                part_name.push('Batang')
            } else if(part == "2") {
                part_name.push('Buah')
            } else if(part == "3") {
                part_name.push('Akar')
            }
        }
        if(part_name.length == 4){
            return ['Seluruhnya terkena']
        } else{
            return part_name
        }
    }

    getPostTime(timemilis){
        var dateNow = new Date();
        var dateOld = new Date(timemilis);

        var minutes = dateOld.getMinutes();
        var hours = parseInt(Math.abs(dateNow - dateOld) / 36e5);
        var dateDiff = parseInt((dateNow-dateOld)/(24*3600*1000));

        if(hours < 24){
            if(hours==0){
                return minutes + ' menit'
            } else{
                return hours + ' jam'
            }
        } else{
            return dateDiff + ' hari'
        }
    }
    
    getDate(timemilis){
        var d = new Date(timemilis);
        var month = new Array();
        month[0] = "Januari";
        month[1] = "Februari";
        month[2] = "Maret";
        month[3] = "April";
        month[4] = "Mei";
        month[5] = "Juni";
        month[6] = "Juli";
        month[7] = "Agustus";
        month[8] = "September";
        month[9] = "Oktober";
        month[10] = "November";
        month[11] = "Desember";

        var date = d.getDate() + " " + month[d.getMonth()] + " " + d.getFullYear();
        var time = d.getHours() + ":" + d.getMinutes();
        var dateTime = time + ' - ' + date;
        return dateTime;
    }

    fetchCommunity(idx){
        var arrTemp = this.state.payload
        fetch("https://tania-community-service.azurewebsites.net/website/v2/community/all?item_group=" + idx, {method: "GET"})
        .then(response => response.json())
        .then(data => {
            var payload = data['payload']
            if(payload.length > 0){
                for(var idx in payload){
                    var id = ""
                    if(payload[idx]["_id"] != null){id = payload[idx]["_id"]}

                    var profile_pict = ""

                    var from_name = "Anonymous"
                    if(payload[idx]["from_name"] != null){from_name = payload[idx]["from_name"]}
                    
                    var posted_time = ""
                    if(payload[idx]["asked_at"] != null){posted_time = payload[idx]["asked_at"]}
                    
                    var plant_image = ""
                    if(payload[idx]["post"] != null && payload[idx]["post"]["plant"] != null){plant_image = payload[idx]["post"]["plant"] + ".png"}

                    var plant = ""
                    if(payload[idx]["post"] != null && payload[idx]["post"]["plant"] != null){plant = payload[idx]["post"]["plant"]}

                    var part = []
                    if(payload[idx]["post"] != null && payload[idx]["post"]["plant_part"] != null){part = payload[idx]["post"]["plant_part"]}

                    var msg_pict = []
                    if(payload[idx]["post"] != null && payload[idx]["post"]["images"] != null){msg_pict = payload[idx]["post"]["images"]}

                    var description = ""
                    if(payload[idx]["post"] != null && payload[idx]["post"]["description"] != null){description = payload[idx]["post"]["description"]}
                
                    var location = ""
                    if(payload[idx]["post"] != null && payload[idx]["post"]["location"] != null){location = payload[idx]["post"]["location"]}
                    
                    var num_of_likes = (payload[idx]["likes"]).length
                    var num_of_comments = payload[idx]["num_of_comments"]

                    arrTemp.push({"id": id, "profile_pict": profile_pict, "from_name": from_name, "posted_time": posted_time, "plant_image": plant_image, "plant": plant, "part":part, "msg_pict": msg_pict, "description": description, "location": location, "num_of_likes": num_of_likes, "num_of_comments": num_of_comments})

                }
                this.setState({payload: arrTemp})
            } else{
                this.setState({idx: -999})
            }
        })
    }

    fetchCatalog(){
        var arrTemp = []
        fetch("https://tania-catalog-service.azurewebsites.net/tania/v1/catalog/plants/0", {method: "GET"})
        .then(response => response.json())
        .then(data => {
            var catalog = data["data"]
            for(var idx in catalog){
                
                var plant_id = ""
                plant_id = catalog[idx]["_id"]
    
                var plant_name = ""
                plant_name = catalog[idx]["plant_name"]
                    
                arrTemp.push({"plant_id": plant_id, "plant_name": plant_name})
                    
            }
            this.setState({catalog: arrTemp})
        })
    }

    getScroll(){
        if(document.getElementById("com-body").scrollHeight - document.getElementById("com-body").scrollTop == document.getElementById("com-body").clientHeight){
            console.log(this.state.idx)
            if(this.state.idx != -999){
                var new_idx = this.state.idx + 1
                this.setState({idx: new_idx}, () => this.fetchCommunity(this.state.idx))
            }
        }
    }

    componentDidMount(){
        this.fetchCommunity(this.state.idx)
        this.fetchCatalog()
    }

    render(){

        return(

            <Layout>
                <Head title = "Community"/>
                <div id = "com-main-container">
                    
                    <div id = "com-title">

                        <div id = "com-title-primary">Community</div>

                        <div id = "com-join-btn">Join Us</div>
                        <div id = "com-join-btn-2">Join Us</div>

                    </div>

                    <div id = "com-body" onScroll={() => this.getScroll()}>

                        {this.state.payload.length == 0 && this.state.catalog.length == 0 && <div class="com-loader"></div>}
                        
                        {this.state.payload.map((payload) => (
                        
                            <div class = "com-item">
                                <div class = "com-item-header">
                                    <Row>
                                        <div class = "item-header-profic"></div>
                                        <Col>
                                            <div class = "item-header-container">
                                                <div class = "item-header-name">{payload.from_name}</div>
                                                <div class = "item-header-divider"> </div>
                                                <div class = "item-header-time">{this.getPostTime(payload.posted_time)}</div>
                                            </div>
                                            <div class = "item-header-location">{payload.location}</div>
                                        </Col>
                                    </Row>
                                </div>
                                <div class = "com-item-content">
                                    <div class = "item-content-category">
                                        {payload.plant != "" && <div class = "content-category-plant">
                                            <img class = "content-category-logo" src = {payload.plant_image}/>
                                            {this.getPlantName(payload.plant)}
                                        </div>}
                                        {this.getPartName(payload.part).map((part) => (
                                            <div class = "content-category-part">
                                                <div class = "part-logo"></div>
                                                {part}
                                            </div>
                                        ))}
                                    </div>
                                    <div class = "item-content-msg">
                                        {payload.description}
                                    </div>
                                    
                                    {payload.msg_pict.length != 0 && <img class = "content-pict-main" src = {payload.msg_pict[0]}/>}
                                    
                                    {payload.msg_pict.length != 0 && <div class = "content-pict-container">

                                        {payload.msg_pict.filter(function(item){return item != payload.msg_pict[0]}).map((img) => (
                                            <img class = "content-pict" src = {img}/>
                                        ))}

                                    </div>}
                                    
                                </div>
                                <div class = "com-item-footer">
                                    <div class = "item-footer-menu">
                                        <img class = "menu-logo" src = {Love}/>
                                        <div class = "menu-txt">{payload.num_of_likes} suka</div>
                                    </div>
                                    <div class = "item-footer-menu" onClick = {() => 
                                        navigate("/community-detail", {
                                            state: {
                                                id: payload.id,
                                                profle_pict: payload.profile_pict,
                                                from_name: payload.from_name,
                                                posted_time: this.getPostTime(payload.posted_time),
                                                plant_image: payload.plant_image,
                                                plant: this.getPlantName(payload.plant),
                                                part: payload.part,
                                                msg_pict: payload.msg_pict,
                                                description: payload.description,
                                                location: payload.location,
                                                num_of_likes: payload.num_of_likes,
                                                num_of_comments: payload.num_of_comments,
                                                date: this.getDate(payload.posted_time)
                                            }})
                                        }>
                                        <img class = "menu-logo" src = {Comment}/>
                                        <div class = "menu-txt">{payload.num_of_comments} komentar</div>
                                    </div>
                                    <div class = "item-footer-menu">
                                        <img class = "menu-logo" src = {Share}/>
                                        <div class = "menu-txt">Bagikan</div>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {this.state.payload.length != 0 && this.state.idx != -999 && <div class = "com-loader-2"></div>}
                    </div>

                    <div id = "com-footer">
                        <div id = "com-footer-text">
                            Join our supportive community. Download Dr. Tania app now.
                        </div>
                        <div id = "com-footer-logo">
                            <img id = "com-icon" src = {GooglePlay}/>
                        </div>
                    </div>

                </div>
            </Layout>
        
        );
    }
}

export default Community;